// Chart js (import all)
import Chart from 'chart.js/auto';
import ChartDataLabels from 'chartjs-plugin-datalabels';


// // Chart.js
// import { Chart } from 'chart.js';

// // Chart Scales
// import { CategoryScale, LinearScale } from 'chart.js';
// Chart.register(CategoryScale, LinearScale);


// // Bar Chart
// import { BarController, BarElement } from 'chart.js';
// Chart.register(BarController, BarElement);

// // Line Chart
// import { LineController, LineElement, PointElement } from 'chart.js';
// Chart.register(LineController, LineElement, PointElement);

// // Doughnut Chart
// import { DoughnutController } from 'chart.js';
// Chart.register(DoughnutController);

// // Pie Chart
// import { PieController } from 'chart.js';
// Chart.register(PieController);

// // Pie/Doughnut Chart Element
// import { ArcElement } from 'chart.js';
// Chart.register(ArcElement);


// import 'chartjs-adapter-luxon';
import 'chartjs-adapter-moment';
window.ChartDataLabels = ChartDataLabels;
window.Chart = Chart;
