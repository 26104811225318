export class LookupSettings {
    constructor(pageDatatable, config) {
        this.pageDatatable = pageDatatable;
        this.config = config;
        this.formErrorMessage = new FormErrorMessage();
    };

    showModal = undefined;
    createModal = undefined;
    pageDatatable = undefined;
    formErrorMessage = undefined;
    config = undefined;


    initialize() {
        let self = this;

        //Datatable
        self.pageDatatable.initializeDatatable(`#${self.config.dataTableId}`);

        //Show Create
        $(`#${self.config.createButton}`).on('click', evt => {
            self.clearCreateModal();
            self.formErrorMessage.clearErrors();
            self.getCreateModal().show();
        });
        //Create Submit
        $(`#${self.config.createModalId} .js-submit-button`).on('click', evt => { self.create(); });

        //Update Submit
        $(`#${self.config.showModalId} .js-submit-button`).on('click', evt => { self.update(); });

        //Delete Submit
        $(`#${self.config.showModalId} .js-delete-button`).on('click', evt => { self.delete(); });
    };
    lookupRowCallback(row, data) {
        let self = this;
        $(row).data('id', data.id);
        $(row).attr('role', 'button');
        $(row).on('click', function (e) {
            self.updateShowModal(data);
            self.formErrorMessage.clearErrors();
            self.getShowModal().show();
        });
    };
    getCreateModal() {
        let self = this;
        if (typeof (self.createModal) === 'undefined')
            self.createModal = new bootstrap.Modal(document.getElementById(self.config.createModalId));
        return self.createModal;
    };

    clearCreateModal() {
        let self = this;
        $(`#${self.config.createModalId}`).find('.js-field-data').each((idx, e) => {
            let name = $(e).attr('name');
            if (typeof (name) === 'undefined')
                return;

            let inputType = $(e).attr('type');
            if (inputType == 'checkbox' || inputType == 'radio') {
                $(e)[0].checked = $(e).data('default-checked') ? true : false;
            }
            else {
                $(e).val('');
            }
        });
    };

    getShowModal() {
        let self = this;
        if (typeof (self.showModal) === 'undefined')
            self.showModal = new bootstrap.Modal(document.getElementById(self.config.showModalId));

        return self.showModal;
    };

    updateShowModal(data) {
        let self = this;
        $(`#${self.config.showModalId}`).find('.js-field-data').each((idx, e) => {
            let name = $(e).attr('name');
            if (typeof (name) === 'undefined')
                return;


            let inputType = $(e).attr('type');

            if (inputType == 'checkbox' || inputType == 'radio') {
                $(e)[0].checked = data[name] ? true : false;
            }
            else {
                $(e).val(data[name]);
            }
        });
    };

    create() {
        let self = this;
        let data = {
            _token: self.config.csrf
        };
        self.formErrorMessage.elements = [];
        $(`#${self.config.createModalId}`).find('.js-field-data').each((idx, e) => {
            let name = $(e).attr('name');

            if (typeof (name) === 'undefined')
                return;

            if ($(e).attr('type') == 'checkbox' || $(e).attr('type') == 'radio') {
                if ($(e).prop('checked')) {
                    data[name] = $(e).val();
                }
            }
            else {
                data[name] = $(e).val();
            }
            self.formErrorMessage.elements.push(e);
        });


        // Send the AJAX post request with the CSRF token
        $.ajax({
            url: self.config.crudUrls.create,
            method: 'POST',
            data: data,
            success: function (data) {
                //draw table
                self.pageDatatable.reload();
                self.getCreateModal().hide();
            },
            error: function (xhr, status, error) {
                self.formErrorMessage.handleErrorResponse(xhr, status, error);
            }
        });
    };
    update() {
        let self = this;
        let data = {
            _token: self.config.csrf,
        };

        self.formErrorMessage.elements = [];
        // Collect data
        $(`#${self.config.showModalId}`).find('.js-field-data').each((idx, e) => {
            let name = $(e).attr('name');

            if (typeof (name) === 'undefined')
                return;

            if ($(e).attr('type') == 'checkbox' || $(e).attr('type') == 'radio') {
                if ($(e).prop('checked')) {
                    data[name] = $(e).val();
                }
            }
            else {
                data[name] = $(e).val();
            }

            self.formErrorMessage.elements.push(e);

        });
        console.log(data);
        // Ajax
        let id = data.id;
        let url = self.config.crudUrls.update.replace('__ID__', id);

        $.ajax({
            url: url,
            method: 'PATCH',
            data: data,
            success: function (data) {
                // location.reload();
                self.pageDatatable.reload();
                self.getShowModal().hide();
            },
            error: function (xhr, status, error) {
                self.formErrorMessage.handleErrorResponse(xhr, status, error);
            }
        });
    };

    delete() {
        let self = this;
        let id = $(`#${self.config.showModalId}`).find('.js-field-data[name="id"]').val()
        let url = self.config.crudUrls.destroy.replace('__ID__', id);

        if (confirm(self.config.deleteConfirmation)) {
            $.ajax({
                url: url,
                method: 'DELETE',
                data: {
                    _token: self.config.csrf,
                },
                success: function (data) {
                    //draw table
                    // location.reload();
                    self.pageDatatable.reload();
                    self.getShowModal().hide();
                },
                error: function (xhr, status, error) {
                    if (xhr.status == 422)
                        alert(xhr?.responseJSON?.message);
                }
            });
        }
    };

}
