import c from '../entities/Const'

export class YearSession {
    constructor(yearSession) {
        Object.assign(this,yearSession);
    };

    isActive()
    {
        return (this.status & Const.STATUS_ACTIVE) != 0;
    }
    isNewRegistrationOpen()
    {
        return (this.status & Const.STATUS_NEW_REGISTRATION_OPEN) != 0;
    }
    isRenewalRegistrationOpen()
    {
        return (this.status & Const.STATUS_RENEWAL_OPEN) != 0;
    }
}

YearSession.Const = c.YearSession;
