import './bootstrap';
import Utils from './utils';
window.Utils = Utils;


import moment from 'moment/moment';
window.moment = moment;

import './jquery';
import './chart';
import './datatable';
import './external/daterangepicker'


import { PageDatatableList } from "./components/PageDatatableList";
window.PageDatatableList = PageDatatableList;

import { FormErrorMessage } from "./components/FormErrorMessage";
window.FormErrorMessage = FormErrorMessage;

import { LookupSettings } from "./components/LookupSettings";
window.LookupSettings = LookupSettings;

import { FileUploader } from "./components/FileUploader";
window.FileUploader = FileUploader;

//TabControl
import { TabControl } from "./components/TabControl";
window.TabControl = TabControl;

import { Sortable } from 'sortablejs'
window.Sortable = Sortable;

import { Toast } from './components/Toast';
window.Toast = Toast

// Lang
import { Lang } from './components/Lang';
window.Lang = Lang;

// Entities
import { Entities } from './entities/Entities';
window.Entities = Entities;

$(() => {
    $('.js-form-parser').on('submit', evt => {

        $(evt.target).find('input').each((idx, e) => {
            let parser = $(e).data('parser');
            switch (parser) {
                case 'currency':
                    $(e).val(parseFloat($(e).val().replace(/[^0-9.-]+/g, "")));
                    break;
                //...
            }


        });
        return true;
    });

});

import * as XLSX from 'xlsx';
window.XLSX = XLSX;
