
import colorLib from '@kurkle/color';
export default class Utils {
    static chartColors = {
        red: 'rgb(255, 99, 132)',
        orange: 'rgb(255, 159, 64)',
        yellow: 'rgb(255, 205, 86)',
        green: 'rgb(75, 192, 192)',
        blue: 'rgb(54, 162, 235)',
        purple: 'rgb(153, 102, 255)',
        grey: 'rgb(201, 203, 207)'
    };
    static transparentize(value, opacity) {
        var alpha = opacity === undefined ? 0.5 : 1 - opacity;
        return colorLib(value).alpha(alpha).rgbString();
    };

    static dateRangePickerPresets = {
        singleDateTime: {
            singleDatePicker: true,
            showDropdowns: true,
            timePicker: true,
            timePicker24Hour: false,
            locale: {
                format: 'D/M/YYYY h:mm A',
                submitFormat: 'YYYY-MM-DD HH:mm:ss'
            }
        },
        singleDate: {
            singleDatePicker: true,
            showDropdowns: true,
            timePicker: false,
            timePicker24Hour: false,
            locale: {
                format: 'D/M/YYYY',
                submitFormat: 'YYYY-MM-DD'
            },
            // autoApply: true
        },
        dateRange: {
            singleDatePicker: false,
            showDropdowns: true,
            timePicker: false,
            timePicker24Hour: false,
            locale: {
                format: 'D/M/YYYY',
                submitFormat: 'YYYY-MM-DD'
            },
            // autoApply: true
        },

    };

    static initializeDateRangePicker(selector, preset) {
        $(selector).each((idx, e) => {

            let m = moment($(e).val());



            if (m.isValid())
                $(e).val(m.format(preset.locale.format));

            $(e).daterangepicker(preset);
            if (!m.isValid())
                $(e).val(null);

            let name = $(e).attr('name');
            let picker = $(e).data('daterangepicker');

            if (typeof (name) === 'undefined')
                return;

            if (typeof (picker) === 'undefined')
                return;

            let hiddenElement = $('<input type="hidden">');
            hiddenElement.attr('name', name);
            $(e).removeAttr('name');
            $(e).after(hiddenElement);
            $(e).data('daterangepicker-he', hiddenElement);
            if (m.isValid())
                $(hiddenElement).val(picker.startDate.format(preset.locale.submitFormat));
            else
                $(hiddenElement).val(null);

            if ($(e).hasClass('js-field-data'))
                $(hiddenElement).addClass('js-field-data');

            $(e).on('hide.daterangepicker', (evt, p) => {
                $($(evt.target).data('daterangepicker-he')).val(p.startDate.format(preset.locale.submitFormat));
            });

            $(e).on('apply.daterangepicker', (evt, p) => {
                $($(evt.target).data('daterangepicker-he')).val(p.startDate.format(preset.locale.submitFormat));
            });

        });
    }
    static renderObjectToText(input, format = 'html', labelLookup = {}, dataLookup = {}, getLabelCallback = undefined, getTextCallback = undefined) {
        let self = this;
        let obj = input;

        if (typeof (obj) === 'string') {
            try {
                obj = JSON.parse(input);
            }
            catch (e) {
                console.log(input);
                obj = undefined;
            }
        }
        if (typeof (obj) !== 'object' || obj == null) {
            return undefined;
        }

        let output = '';
        Object.entries(obj).forEach(([key, value]) => {
            let label = undefined;
            let text = undefined;
            if (typeof (getLabelCallback) === 'function') {
                label = getLabelCallback(key, value, obj, labelLookup, dataLookup);
            }
            else {
                label = labelLookup[key] ?? key;
            }

            if (typeof (getTextCallback) === 'function') {
                text = getTextCallback(key, value, obj, labelLookup, dataLookup);
            }
            else {
                text = (dataLookup[key] ?? {})[value] ?? value;
            }

            if (format == 'html') {
                if (output.length > 0) {
                    output += '<br>';
                }
                output += `<strong>${this.escapeHtml(label)}:</strong> ${this.escapeHtml(text)}`;
            } else if(format == 'xlsx') {
                if (output.length > 0) {
                    output += "; ";
                }
                output += `${label}: ${text}`;
            }
            else {
                if (output.length > 0) {
                    output += "\n";
                }
                output += `${label}: ${text}`;
            }
        });
        return output;
    }
    static escapeHtml(text) {
        return $('<inputarea></inputarea>').html(text).text();
    }

    static formatCurrency(inputValue, prefix) {
        // Remove existing commas and non-numeric characters
        inputValue = inputValue.replace(/,/g, '');
        inputValue = inputValue.replace(/\D/g, '');

        // Format the input as currency
        const formattedValue = new Intl.NumberFormat('en-US', {
            style: "decimal",
            minimumFractionDigits: 2,
        }).format(inputValue / 100);

        if (typeof (prefix) === 'undefined')
            prefix = '';


        // Update the input value with the formatted currency
        return `${prefix}${formattedValue}`;
    }

    static formatInputCurrency(input) {

        let prefix = $(input).data('prefix');
        if (typeof (input?.value) !== 'undefined')
            input.value = this.formatCurrency(input.value, prefix);

    }

    static collectJsFieldInputValues(parent, formErrorMessage) {
        let data = {};

        $(parent).find('.js-field-data').each((idx, e) => {
            let field = $(e).attr('name');
            let parser = $(e).data('parser');

            let type = $(e).attr('type');
            if (type == 'radio' || type == "checkbox") {
                if ($(e).is(':checked'))
                    data[field] = $(e).val();

            } else if (parser == 'currency') {
                data[field] = parseFloat($(e).val().replace(/[^0-9.-]+/g, ""));
            } else {
                if (typeof (field) === 'undefined')
                    return;

                data[field] = $(e).val();
            }
            if (typeof (formErrorMessage) !== 'undefined')
                formErrorMessage.elements.push(e);
        });

        return data;

    }
    static parseInputForm(form) {

    }

}
