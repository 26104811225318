export class FormErrorMessage {
    constructor(parent, errorsBag, onRenderError) {
        if (typeof (parent) === 'undefined')
            return;

        this.onRenderError = onRenderError;
        this.autoGetElements(parent);
        this.handleError(errorsBag);

    };
    onRenderError = undefined;
    elements = [];
    errorMessageElements = [];
    autoGetElements = function (e) {
        let _this = this;
        $(e).find('input').each((idx, e) => {
            let name = $(e).attr('name');
            _this.elements.push(e);
        });

        $(e).find('select').each((idx, e) => {
            let name = $(e).attr('name');
            _this.elements.push(e);
        });
        $(e).find('textarea').each((idx, e) => {
            let name = $(e).attr('name');
            _this.elements.push(e);
        });
    };
    handleError = function (errorsBag) {
        let _this = this;
        this.clearErrors();
        this.elements.forEach(e => {
            let name = $(e).attr('name');
            if (typeof (name) !== 'undefined' && typeof (errorsBag[name]) !== 'undefined') {
                let targetElementBox = e;
                let targetElementText = e;

                if ($(e).attr('type') == 'hidden' || $(e).attr('type') == 'checkbox' || $(e).data('error-handler') == 'hidden') {
                    targetElementText = $(e).parent();
                    targetElementBox = $(targetElementText).find('input[type!="hidden"][type!="checkbox"][type!="radio"]');
                }

                $(targetElementBox).addClass('hasError');

                let errorMessage = $('<div class="form-error-message">');
                errorMessage.html(errorsBag[name]);
                //append element
                //tambah error message
                $(targetElementText).after(errorMessage);
                _this.errorMessageElements.push(errorMessage[0]);
                if (typeof (_this.onRenderError) === 'function') {
                    _this.onRenderError(name, errorsBag[name]);
                }
            }
        });
    }
    handleErrorResponse = function (xhr, status, error) {
        if (xhr.status == 422) {
            //form error
            let errorsBag = xhr.responseJSON.errors;
            this.handleError(errorsBag);
        }
    };
    clearErrors() {
        this.errorMessageElements.forEach(e => {
            $(e).remove();
        })
        this.errorMessageElements = [];

        this.elements.forEach(e => {
            $(e).removeClass('hasError');
        })
    }

}
