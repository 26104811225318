export class PageDatatableList {
    static storeSearchFilters(key, filters) {
        sessionStorage.setItem(`PageDatatableList_${key}`, JSON.stringify(filters));
    }
    constructor(config, preset, filterKey) {
        this.config = config;
        this.preset = preset ?? 'default';

        if (typeof (filterKey) === 'string') {
            try {
                const storageKey = `PageDatatableList_${filterKey}`;
                const searchFiltersValue = sessionStorage.getItem(storageKey) ?? undefined;
                this.searchFilters = JSON.parse(searchFiltersValue);
                sessionStorage.removeItem(storageKey);
            }
            catch {
                this.searchFilters = undefined;
            }
        }
    };
    preset = 'default';
    config = null;

    search = null;
    dataTableObject = null;
    dataTableId = null;
    onXhr = undefined;
    initializeDatatable(dataTableId) {

        let self = this;
        //default datatable config
        for (let n = 0; n < self.config.datatableColumns.length; n++) {
            if (typeof (self.config.datatableColumns[n].render) === 'undefined') {
                self.config.datatableColumns[n].render = DataTable.render.text(); //use text renderer as default
            }
        }
        let dtConfig = {
            "processing": true,
            "serverSide": true,
            "scrollX": true,
            "searching": false,

            "ajax": {
                url: self.config.dataUrl,
                type: "POST",
                data: function (d) {

                    d._token = self.config._token;
                    d.filters = self.config.filters;
                    d.exportColumns = self.config.exportColumns;
                    d.search = self.search;
                    d.cols = ['id'];
                    self.config.datatableColumns.forEach(e => {
                        d.cols.push(e.data);
                    });
                }
            },
            "columns": self.config.datatableColumns
        };


        if (this.preset == 'simple') {
            dtConfig.searching = true;
            dtConfig.serverSide = false;
            dtConfig.processing = false;
            dtConfig.paging = true;
            dtConfig.dom = '<"flex-align-right"f>t<"flex-align-center mt-2"p>';
        }
        else if (this.preset == 'report') {
            dtConfig.lengthChange = true;
            dtConfig.searching = true;
            dtConfig.serverSide = false;
            dtConfig.processing = false;
            dtConfig.ajax = undefined;
            dtConfig.paging = true;
            dtConfig.dom = 'l<"flex-align-right"f>t<"flex-align-center mt-2"p>';
        }
        else if (this.preset == 'default') {
            dtConfig.lengthChange = true;
            dtConfig.dom = `l<"flex-align-right"f>t<"flex-align-center mt-2"p>`;
        }

        if (typeof (self.config.datatableConfig) === 'object')
            Object.assign(dtConfig, self.config.datatableConfig);

        if (typeof (self.config.rowTemplate) === 'string') {
            let rowTemplate = self.config.rowTemplate;
            if (typeof (dtConfig.createdRow) === 'undefined') {

                dtConfig.createdRow = function (row, data, index) {

                    let colSpan = $(row).find('td').length;
                    let cellContents = $('<div>');
                    let tds = [];
                    $(row).find('td').each((idx, e) => {
                        cellContents.append($(e));
                        tds.push($(e));
                    });

                    cellContents = [];

                    // override row
                    let rowCell = $(`<td colspan="${colSpan}">`);

                    rowCell.html($(`#${rowTemplate}`).html());

                    rowCell.find('.dt-cell').each((idx, e) => {
                        let colIdx = $(e).data('column-index');

                        if (typeof (colIdx) === 'undefined')
                            return;

                        let content = tds[colIdx]?.html() ?? '';
                        $(e).html(content);

                    });

                    $(row).html('').append(rowCell);

                };
                dtConfig.scrollX = false;
            }

        }
        this.onSearchSubmit();
        this.dataTableObject = $(dataTableId).DataTable(dtConfig);
        this.dataTableId = dataTableId;

        this.dataTableObject.on('xhr.dt', function (e, settings, json, xhr) {
            if (typeof (self.onXhr) === 'function')
                self.onXhr(e, settings, json, xhr);
        });

        if (dtConfig.sortbox) {
            const sortBoxContainer = $('<div class="d-flex justify-content-end mb-2">');
            sortBoxContainer.insertBefore($(dataTableId));
            this.initializeSortBox(sortBoxContainer);
        }
        return this;
    };
    initializeSortBox(sortBoxContainer) {

        const dv = $('<div class="d-flex col-12 col-md-8 col-lg-6 col-xl-4">');
        const order = this.config.datatableConfig.order[0] ?? [];

        const select = $('<select class="form-control rounded-0 rounded-start dt-sortselect">');

        this.config.datatableColumns.forEach((col, idx) => {
            if (!col.sortable)
                return;

            const option = $('<option></option>');
            if (typeof (order) !== 'undefined' && (order[0] ?? undefined) == idx) {
                option[0].selected = true;
            }


            option.val(idx);
            option.text(col.title);
            select.append(option);
        });

        const orderBy = $('<button class="btn btn-light border rounded-0 rounded-end dt-sortorder">');

        orderBy.on('click', e => {
            if ($(e.target).val() == 'asc') {
                $(e.target).val('desc').html('<i class="fa-solid fa-chevron-down pe-none"></i>');
            }
            else {
                $(e.target).val('asc').html('<i class="fa-solid fa-chevron-up pe-none"></i>');
            }

            this.applyOrder();
        });

        if (typeof (order) !== 'undefined' && (order[1] ?? undefined) == 'asc') {
            orderBy.html('<i class="fa-solid fa-chevron-up pe-none"></i>').val('asc');
        }
        else {
            orderBy.html('<i class="fa-solid fa-chevron-down pe-none"></i>').val('desc');
        }

        select.on('change', e => {
            const selected = $(e.target);
            this.applyOrder();

        });

        dv.append(select);
        dv.append(orderBy);
        sortBoxContainer.append(dv);

    };
    applyOrder() {

        const idx = $(this.dataTableId).parent().find('.dt-sortselect').val();
        const order = $(this.dataTableId).parent().find('.dt-sortorder').val();
        this.dataTableObject.order([idx, order]);

        if (!this.dataTableObject.settings()[0].ajax) {
            this.dataTableObject.draw();
        }
        else {
            this.dataTableObject.ajax.reload();
        }

    };
    initializeSearchFields(searchContainerId) {
        //create search field elements
        var owner = this;

        this.config.searchFields.forEach(searchField => {
            let inputType = searchField.type;
            var e;
            if (inputType == 'select') {
                e = owner.createSearchFieldSelect(searchField.title, searchField.data, searchField.options, searchField.afterRender);
            }
            else if (inputType == 'datetime') {
                e = owner.createSearchFieldDateTime(searchField.title, searchField.data, searchField.options, searchField.afterRender);
            }
            else {
                e = owner.createSearchFieldText(searchField.title, searchField.data, searchField.options, searchField.afterRender);
            }

            $(searchContainerId).append(e.element);

            //after render data

            $(e.element).find(`.search-field[data-field="${searchField.data}"]`).data('field-type', inputType);
            $(e.element).find(`.search-field[data-field="${searchField.data}"]`).data('options', e.options);

        });

        //Apply searchFilters
        if (typeof (this.searchFilters) !== 'undefined') {
            Object.entries(this.searchFilters).forEach(([field, value]) => {
                const input = $(searchContainerId).find(`.search-field[data-field="${field}"]`)[0];
                const inputEnable = $(searchContainerId).find(`.search-field-enable[data-field="${field}"]`)[0];

                if (typeof (input) !== 'object') {
                    return;
                }
                const inputType = $(input).data('field-type');
                switch (inputType) {
                    case 'datetime':
                        try {
                            const datePicker = $(input).data('daterangepicker');
                            datePicker.setStartDate(moment(value));
                            datePicker.setEndDate(moment(value));
                        }
                        catch {

                        }

                        break;
                    default:
                        input.value = value;
                        break;
                }


                if (typeof (inputEnable) === 'object') {
                    inputEnable.checked = true;
                }
            })
        }

        //attach search functions
        $('.search-field').on('input', function () {
            owner.onSearchFieldChanging(this);
        })
            .on('keypress', function (e) {
                if (e.key === 'Enter')
                    owner.onSearchSubmit().dataTableObject.ajax.reload();
            });

        $('.search-field.js-daterangepicker').each((idx, e) => {
            $(e).on('change', evt => {
                owner.onSearchFieldChanging(evt.target);
            });

        })

        $('.search-submit').on('click', function () {
            owner.onSearchSubmit().dataTableObject.ajax.reload();
        });
        $('.search-clear').on('click', function () {
            owner.onSearchClear();
        });

        return this;
    };

    onSearchClear() {
        $('.search-field-enable').each(function (idx, e) {
            let field = $(e).data('field');
            if (typeof field === 'undefined')
                return;

            if ($(e).is(':checked')) {
                $(e)[0].checked = false;
            }

            $('.search-field[data-field="' + field + '"]').val('');
        });
    };
    onSearchSubmit() {
        //get all search parameters
        let search = [];
        $('.search-field-enable').each(function (idx, e) {
            let field = $(e).data('field');
            if (typeof field === 'undefined')
                return this;

            if ($(e).is(':checked')) {
                const searchField = $('.search-field[data-field="' + field + '"]');
                let parameter = searchField.val();


                if (typeof parameter === 'undefined' || parameter.length <= 0)
                    return this;

                if (searchField.data('field-type') == 'datetime') {
                    let format = searchField?.data('options')?.locale?.submitFormat ?? undefined;
                    if (!format) {
                        format = 'YYYY-MM-DD HH:mm:ss';
                    }

                    const formatted = searchField?.data('daterangepicker')?.startDate?.format(format) ?? undefined;
                    if (formatted) {
                        parameter = formatted;
                    }
                }
                //add to search
                search.push({
                    field: field,
                    parameter: parameter
                })
            }
        });
        this.search = search;
        return this;
    };
    onSearchFieldChanging(sender) {

        let field = $(sender).data('field');

        if (typeof field === 'undefined')
            return;

        let selector = '.search-field-enable[data-field="' + field + '"]';

        if (sender.value.length > 0) {
            $(selector)[0].checked = true;
        }
        else
            $(selector).removeAttr('checked');


    };
    createSearchFieldDateTime(label, field, options = null, afterRender = null) {
        var e = $($('#template-search-field-daterangepicker').html());

        e.find('.search-field-enable').each(function (idx, element) {
            element.dataset.field = field;
        });

        let dtOptions = options;
        if (!dtOptions) {
            dtOptions = Utils.dateRangePickerPresets.singleDateTime;
        }

        e.find('.search-field').each(function (idx, element) {
            element.dataset.field = field;
            $(element).daterangepicker(dtOptions);
            $(element).val('');
        });

        e.find('.search-field-label').each(function (idx, element) {
            $(element).html(label);
        });

        return {
            element: e,
            label: label,
            field: field,
            options: dtOptions,
            afterRender: afterRender
        };
    }

    createSearchFieldText(label, field, options = null, afterRender = null) {
        var e = $($('#template-search-field-text').html());

        e.find('.search-field-enable').each(function (idx, element) {
            element.dataset.field = field;
        });

        e.find('.search-field').each(function (idx, element) {
            element.dataset.field = field;
        });

        e.find('.search-field-label').each(function (idx, element) {
            $(element).html(label);
        });

        return {
            element: e,
            label: label,
            field: field,
            options: options,
            afterRender: afterRender
        };
    };
    createSearchFieldSelect(label, field, options = null, afterRender = null) {
        var e = $($('#template-search-field-select').html());

        e.find('.search-field-enable').each(function (idx, element) {
            element.dataset.field = field;
        });
        e.find('.search-field').each(function (idx, element) {

            element.dataset.field = field;

            if (options !== null) {
                options.forEach(e => {

                    $(element).append($('<option></option').val(e.value).html(e.text));
                });
            }

            if (typeof afterRender === 'function')
                afterRender(element);
        });

        e.find('.search-field-label').each(function (idx, element) {
            $(element).html(label);
        });

        return {
            element: e,
            label: label,
            field: field,
            options: options,
            afterRender: afterRender
        };
    };
    destroy() {
        if (typeof (this.dataTableObject) !== null)
            this.dataTableObject.destroy();
    };


    reload() {
        if (typeof (this.dataTableObject) !== null)
            this.dataTableObject.ajax.reload();
    };
    preview(title = undefined, urlView = undefined) {
        let parameters = this.dataTableObject.ajax.params();
        let url = this.dataTableObject.ajax.url();
        if (!url)
            url = this.config.dataUrl;

        if (!parameters)
            parameters = { _token: this.config._token };

        parameters.format = 'array';


        let label = 'dt_' + Date.now().toString() + '_' + Math.floor(Math.random() * 1000000).toString();

        let requestData = {
            url: url,
            data: parameters
        };
        window.sessionStorage.setItem(label, JSON.stringify(requestData));

        let previewWindow = window.open();

        let param = `k=${label}&t=${title}`;

        if (urlView.includes('?'))
            previewWindow.location.href = `${urlView}&${param}`;
        else
            previewWindow.location.href = `${urlView}?${param}`;




    };
    download() {
        let parameters = this.dataTableObject.ajax.params();
        let url = this.dataTableObject.ajax.url();
        if (!url)
            url = this.config.dataUrl;

        if (!parameters)
            parameters = { _token: this.config._token };

        parameters.format = 'xlsx';

        $.ajax({
            type: 'POST',
            url: url,
            data: parameters,
            xhr: function () {
                var xhr = new XMLHttpRequest();
                xhr.onreadystatechange = function () {
                    if (xhr.readyState == 2) {
                        if (xhr.status == 200) {
                            xhr.responseType = "blob";
                        } else {
                            xhr.responseType = "text";
                        }
                    }
                };
                return xhr;
            },
            success: function (data, textStatus, xhr) {
                let blob = new Blob([data], { type: "application/octetstream" });
                let fileName = 'export.xlsx';
                let disposition = xhr.getResponseHeader('Content-Disposition');
                if (disposition && disposition.indexOf('attachment') !== -1) {
                    var filenameRegex = /filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/;
                    var matches = filenameRegex.exec(disposition);
                    if (matches != null && matches[1]) {
                        fileName = matches[1].replace(/['"]/g, '');
                    }
                }

                var isIE = false || !!document.documentMode;
                if (isIE) {
                    window.navigator.msSaveBlob(blob, fileName);
                } else {
                    var url = window.URL || window.webkitURL;
                    let link = url.createObjectURL(blob);
                    var a = $("<a />");
                    a.attr("download", fileName);
                    a.attr("href", link);
                    $("body").append(a);
                    a[0].click();
                    $("body").remove(a);
                }

            }
        });
    };
}
