
export class Entities {};

import { YearSession  } from './YearSession';
Entities.YearSession = YearSession;

import { License } from './License';
Entities.License = License;

import { Auction } from './Auction';
Entities.Auction = Auction;

import { User } from './User';
Entities.User = User;
