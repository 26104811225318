export class FileUploader {
    constructor(e, url, data) {
        let self = this;
        // Object.assign(this.config, config);
        $(e).data('FileUploader', this);
        $(e).on('change', evt => {
            self.upload();
        });
        this.url = url;
        this.data = data;
        this.input = e;
        //create progress element

        this.progressBar = $(`<div class="progress d-none" role="progressbar" aria-label="Upload Progress" aria-valuenow="0" aria-valuemin="0" aria-valuemax="100"><div class="progress-bar progress-bar-striped" style="width: 0%"></div></div>`);
        this.errorMessage = $('<div class="form-error-message text-center"></div>');
        $(e).after(this.errorMessage);
        $(e).after(this.progressBar);
    };

    done(success) {
        this.onSuccess = success;
    };
    fail(fail) {
        this.onError = fail;
    };
    input = undefined;
    url = undefined;
    data = undefined;
    config = {

    };
    onSuccess = undefined;
    onError = undefined;
    abortController = undefined;

    upload(url, data, onSuccess) {


        if (typeof (url) !== 'undefined')
            this.url = url;

        if (typeof (data) !== 'undefined')
            this.data = data;

        if (typeof (onSuccess) !== 'undefined')
            this.onSuccess = onSuccess;


        let self = this
        self.abortController = new AbortController();

        if (self.input.files.length === 0)
            return;

        let file = self.input.files[0];
        let formData = new FormData();

        Object.entries(this.data).forEach(([key, item]) => {
            formData.set(key, item);
        });

        formData.set('file',file);
        self.progressBar.find('div').attr('aria-valuenow', 0).attr('style', 'width:0%');
        self.progressBar.removeClass('d-none');

        self.errorMessage.html('');
        $(self.input).trigger('FileUploader.preupload');

        axios.post(self.url, formData, {
            signal: self.abortController.signal,
            onUploadProgress: progressEvent => {
                var pcg = Math.floor(progressEvent.loaded / progressEvent.total * 100);
                self.progressBar.find('div').attr('aria-valuenow', pcg).attr('style', 'width:' + Number(pcg) + '%');
            }
        })
            .then(res => {
                self.progressBar.find('div').attr('aria-valuenow', 0).attr('style', 'width:0%');
                self.progressBar.addClass('d-none');

                $(self.input).trigger('FileUploader.success',res);
                $(self.input).trigger('FileUploader.postupload');


            })
            .catch(function (res) {
                self.progressBar.find('div').attr('aria-valuenow', 0).attr('style', 'width:0%');
                self.progressBar.addClass('d-none');


                $(self.input).trigger('FileUploader.error',res);
                if(self.errorMessage)
                {
                    //get error message

                    let errorMessage = res?.response?.data?.message ?? 'Server Error!';
                    self.errorMessage.html(errorMessage);
                }
                $(self.input).trigger('FileUploader.postupload');
            });
    }
}

// Extend jquery
jQuery.fn.extend({
    FileUploader: function (config, data) {
        this.each((idx, e) => {
            new FileUploader(e, config, data);
        });

        return this;
    }
});
