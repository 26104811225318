import c from '../components/LangDef'
export class Lang {

    static getItem(paths, obj, index = 0) {

        if (typeof (obj[paths[index]]) !== 'undefined') {
            obj = obj[paths[index]];
            index++;
            if (index != paths.length)
                return this.getItem(paths, obj, index);
            else
                return obj;
        }
        return undefined;
    }

    static get(langPath) {
        const FALLBACK_LANG = 'ms';
        let locale = document.documentElement.lang;

        let targetPaths = langPath.split('.');
        let paths = [locale, ...targetPaths];

        let result = this.getItem(paths,  Lang.lang);

        if (typeof (result) === 'undefined')//try fallback
        {
            paths = [FALLBACK_LANG, ...targetPaths];
            result = this.getItem(paths, Lang.lang);
        }

        return result;
    }

    static renderLookup(lookupId, langPath) {
        let fullPath = `${langPath}.${lookupId}`;
        let render = this.get(fullPath);

        return render;
    }
}
Lang.lang = c;
