export class TabControl {
    index = 0;
    tabChangeCallback = [];
    tag = undefined;
    constructor(tabTag, tagId) {
        this.tag = tabTag;
        if (typeof (tabTag) === 'undefined')
            return;

        let prevId = this.loadIdFromCookie();

        if(prevId != tagId)
        {
            $('.tab-button').each( (idx,e) => {
                let tabGroup = $(e).data('tab-group');
                this.clearCookie(this.getTabCookieName(tabGroup));
            });
            this.storeIdToCookie(tagId);
        }

    };
    getTabCookieName(tabGroup) {
        if (typeof (this.tag) === 'undefined')
            return undefined;

        if(typeof(tabGroup) === 'undefined')
            return 'tab_' + this.tag;

        return 'tab_' + this.tag + "_" + tabGroup;
    };
    clearCookie(name) {
        const date = new Date();
        date.setTime(0);
        const expires = "expires=" + date.toUTCString();
        document.cookie = name + "=;" + expires + ";path=/";
    };
    setCookie(name, value) {
        const date = new Date();
        date.setTime(date.getTime() + (60 * 60 * 1000));
        const expires = "expires=" + date.toUTCString();
        document.cookie = name + "=" + value + ";" + expires + ";path=/";
    };
    getCookie(name) {
        const cookieName = name + "=";
        const decodedCookie = decodeURIComponent(document.cookie);
        const cookieArray = decodedCookie.split(';');

        for (let i = 0; i < cookieArray.length; i++) {
            let cookie = cookieArray[i];
            while (cookie.charAt(0) === ' ') {
                cookie = cookie.substring(1);
            }
            if (cookie.indexOf(cookieName) === 0) {
                return cookie.substring(cookieName.length, cookie.length);
            }
        }

        return undefined;
    };
    loadIdFromCookie() {
        if (typeof (this.tag) === 'undefined')
            return 0;

        return this.getCookie(this.getTabCookieName(undefined) + "_id");
    };
    storeIdToCookie(id) {
        this.setCookie(this.getTabCookieName(undefined)+"_id", id);
    };
    loadIndexFromCookie(tabGroup) {
        if (typeof (this.tag) === 'undefined')
            return 0;
        let tabIndex = this.getCookie(this.getTabCookieName(tabGroup));
        if(typeof(tabIndex) === 'undefined')
            return 0;
        return tabIndex;
    };
    storeIndexToCookie(tabGroup,index) {
        this.setCookie(this.getTabCookieName(tabGroup), index);
    };
    registerCallback(callback) {
        this.tabChangeCallback.push(callback);
    };
    initialize() {
        let _this = this;

        $('.tab-button').on('click',(e) => {
            onClickTabButton(e.target,_this);
        });


        let tabGroups = [];
        $('.tab-button').each( (idx,e) => {
            let tabGroup = $(e).data('tab-group');
            let tabIndex = $(e).data('tab-index');
            let initIndex = this.loadIndexFromCookie(tabGroup);

            if(initIndex == tabIndex)
                onClickTabButton(e,_this);

            if(typeof(tabGroup) === 'undefined')
                tabGroup = '_';

            if(!tabGroups.includes(tabGroup))
                tabGroups.push(tabGroup);
        });

        // //Show default
        // tabGroups.forEach(tabGroup =>{

        //     if(tabGroup == '_')
        //         tabGroup = undefined;

        //     let initIndex = this.loadIndexFromCookie(tabGroup);
        //     if(typeof(initIndex) === 'undefined')
        //         initIndex = 0;

        //     if(typeof(tabGroup) === 'undefined')
        //         $(`.tab-contents[data-tab-index="${initIndex}"]`).not(`[data-tab-group]`).removeClass('d-none');
        //     else
        //         $(`.tab-contents[data-tab-group="${tabGroup}"][data-tab-index="${initIndex}"]`).removeClass('d-none');

        // })


    };

}

function onClickTabButton(e,tabControl) {
    let tabIndex = $(e).data('tab-index');
    let tabGroup = $(e).data('tab-group');

    if (typeof (tabIndex) === 'undefined')
        return;

    tabControl.index = tabIndex;
    if (typeof (tabGroup) === 'undefined') {
        $('.tab-contents').not(`[data-tab-group]`).addClass('d-none');
        $(`.tab-contents[data-tab-index="${tabIndex}"]`).not(`[data-tab-group]`).removeClass('d-none');

        $('.tab-button').not(`[data-tab-group]`).removeClass('active');
        $(`.tab-button[data-tab-index="${tabIndex}"]`).not(`[data-tab-group]`).addClass('active');
    }
    else {
        $(`.tab-contents[data-tab-group="${tabGroup}"]`).addClass('d-none');
        $(`.tab-contents[data-tab-group="${tabGroup}"][data-tab-index="${tabIndex}"]`).removeClass('d-none');

        $(`.tab-button[data-tab-group="${tabGroup}"]`).removeClass('active');
        $(`.tab-button[data-tab-group="${tabGroup}"][data-tab-index="${tabIndex}"]`).addClass('active');
    }
    tabControl.storeIndexToCookie(tabGroup,tabIndex);

    if (Array.isArray(tabControl.tabChangeCallback)) {
        tabControl.tabChangeCallback.forEach(cb => {
            if (typeof (cb) === 'function')
                cb(tabIndex);
        });
    }
    //trigger resize event to fix datatable header not size properly
    window.dispatchEvent(new Event('resize'));
}
