$(() => {
    if (typeof (window.DisableToast) === 'undefined' || !window.DisableToast)
        Toast.initialize();
})

export class Toast {
    static toastContainer = undefined;

    static initialize() {

        Toast.toastContainer = $('<div class="toast-container bottom-0 end-0 p-3">');
        $('main').prepend(Toast.toastContainer);

    }

    static show(message, type = 'success') {

        let toastElement = Toast.createToastElement(message, type)[0];
        $(Toast.toastContainer).append(toastElement);
        bootstrap.Toast.getOrCreateInstance(toastElement).show();
    }

    static getTypeClasses(type) {
        switch (type) {
            case 'primary':
                return 'text-white bg-primary';
            case 'secondary':
                return 'text-white bg-secondary';
            case 'success':
                return 'text-white bg-success';
            case 'info':
                return 'text-black bg-info';
            case 'warning':
                return 'text-black bg-warning';
            case 'danger':
                return 'text-white bg-danger';
            case 'light':
                return 'text-black bg-light';
            case 'dark':
                return 'text-white bg-dark';
            default:
                return '';
        }
    }

    static createToastElement(message, type) {
        let toastElement = $(`<div class="toast align-items-center border-0" role="alert" aria-live="assertive" aria-atomic="true">`);
        toastElement.addClass(Toast.getTypeClasses(type));
        let messageElement = $(`<strong class="me-auto"></strong>`).html($("<textarea/>").html(message).text());
        toastElement.append(
            $('<div class="d-flex">')
                .append($('<div class="toast-body">')
                    .append(messageElement))
                .append($('<button type="button" class="btn-close btn-close-white me-2 m-auto" data-bs-dismiss="toast" aria-label="Close"></button>'))
        );
        return toastElement;
    }
}
